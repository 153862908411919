//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

.ProseMirror p {
  margin: 0;
}

.ProseMirror:focus-visible {
  outline: none;
}

.custom-date-input::-webkit-calendar-picker-indicator {
  display: none;
}

.custom-date-input::-webkit-inner-spin-button,
.custom-date-input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.left-icon {
  stroke: black;
}

.report-element-button:hover {
  background-color: $primary-hover;

  .report-div-icon {
    background-color: $primary-dark-hover;
  }
  .left-icon {
    stroke: $primary-hover;
  }
  .right-icon {
    stroke: $primary-dark-hover;
  }
}

.pencil-icon-style {
  color: $primary !important;
}

.pencil-div:hover {
  background-color: $primary-dark-hover;

  .pencil-icon-style {
    color: $primary-inverse !important;
  }
}

.save-template-btn:hover {
  background-color: $primary !important;
  color: white !important;
}

.save-field-btn:hover {
  background-color: $primary-hover !important;
  color: $primary !important;
}

.grab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}
.grabbing {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.not-started-color {
  color: #5014d0;
}

.not-started-hover:hover {
  background-color: #5014d0;
}
